import React, {FC} from 'react';
import {supportEmail} from "../App";
import {Link} from "react-router-dom";
import {legalRoutes} from "../pages/LegalInfoPage";

interface Props {
}

const Footer: FC<Props> = () => {
  return (
    <footer>
        Silda Group LTD
        Address: 85 Great Portland Street,<br/> First Floor, London, United Kingdom, W1W 7LT
      <div className='d-flex justify-content-between flex-column flex-md-row'>
        <a href={`mailto:${supportEmail}`} target='_blank' className=''>{supportEmail}</a>
        <div className='d-flex flex-column flex-md-row justify-content-center flex-wrap gap-2 text-14 align-items-center mt-3 mt-md-0'>
          <Link to={`/legal-information`}>Legal Information</Link>
          {/*<a href="" target="_blank">Privacy Policy</a>*/}
          <a href={legalRoutes[0].path} target="_blank">Terms of Service</a>
        </div>
      </div>

    </footer>
  );
}

export default Footer;