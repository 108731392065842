import React, {FC} from 'react';
import Header from "./Header";
import CircleOrion from "./CircleOrion";
import Footer from "./Footer";
import Cookies from "./Cookies";

interface Props {
  children: any;
  footer?: boolean;
}

const Layout: FC<Props> = ({children, footer}) => {
  return (
    <>
      <Header />

      <main>
        {children}

        <Cookies />
        <div className="bg">
          <CircleOrion size={'sm'} bg={['yellow']}/>
          <CircleOrion size={'lg'}/>
          <CircleOrion size={'xl'} bg={['yellow', 'red']}/>
          {/*<img src={bg} alt="bg" className='bg-ai'/>*/}
        </div>
      </main>

      {footer && <Footer/>}

    </>
  );
}

export default Layout;