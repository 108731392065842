import React, {FC, useEffect, useState} from 'react';

interface Props {
}

const Cookies: FC<Props> = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const cookies = localStorage.getItem('cookies');
    if (!cookies) {
      setTimeout(() => {
        setVisible(true);
      }, 1000);
    }

  }, []);

  const handleClick = () => {
    setVisible(false);
    localStorage.setItem('cookies', "1")
  }

  return (
    <div className={`cookies${visible ? ' visible' : ''}`}>
      <h3 className='mb-3'>This website uses cookies.</h3>
      <p className='mt-2'>We use cookies to analyze website traffic and optimize your website experience. By accepting
        our use of
        cookies, your data will be aggregated with all other user data.</p>
      <button type='button' className='btn btn-primary w-100 mt-4' onClick={handleClick}>Accept</button>
    </div>
  );
}

export default Cookies;