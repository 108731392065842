import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './assets/css/bootstrap.min.css';
import './assets/css/constants.css';
import './assets/css/fonts.css';
import './assets/css/main.css';
import './assets/css/media.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <App/>
);
