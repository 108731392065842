import React, {FC} from 'react';
import Layout from "../components/Layout";

export const legalRoutes = [
  {path: 'https://docs.google.com/document/d/1N7cIM0e6n53gsn2HdFKgWtjPIJCeK9ftlI0UTSrOAVs/edit?usp=sharing', title: 'Terms of Service'},
  // {path: 'privacy-policy', title: 'Privacy Policy'},
  // {path: 'cookies-policy', title: 'Cookies Policy'},
  {path: 'https://docs.google.com/document/d/1Kpl6Z96Ra4Ki6xSQiQnLoWomh67g_O0aEQSVp0MlaMo/edit?usp=sharing', title: 'Underage Policy'},
  {path: 'https://docs.google.com/document/d/1fSsg1rNpEUlDR9gMN1GyzQlVi-rIrzeRxjNrBvNfwDg/edit?usp=sharing', title: 'Content Deletion Policy'},
  {path: 'https://docs.google.com/document/d/1c2KhKjYjDfzhYyNADzSsO949ndZcs5pZS7vinpU9HQk/edit?usp=sharing', title: 'Prohibited Content Policy'},
  {path: 'https://docs.google.com/document/d/1dOhIsNH03Vk3uYrsvjE61_cknZR0DW30554BVSuCIEY/edit?usp=sharing', title: 'Copyright Compliance Policy'},
  // {path: 'complaint-policy', title: 'Complaint Policy'},
  {path: 'https://docs.google.com/document/d/1CBMFS07vMM2l5u-518-UL3gV_eXHv8nMHn0D0GnRDic/edit?usp=sharing', title: '18 U.S.C. 2257 Exemption'},
];

interface Props {
}

const LegalInfoPage: FC<Props> = () => {
  return (
    <Layout>
      <h3 className='page-title'>Legal Information</h3>
      <div className='row g-3 mt-4'>
        {legalRoutes.map(item => (
          <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
            <a href={item.path} target={'_blank'} className='btn btn-secondary' key={item.path}>
              <div className="btn-content"><span className='letter-uppercase'>{item.title}</span></div>
            </a>
          </div>
        ))}
      </div>
    </Layout>
  );
}

export default LegalInfoPage;