import React, {FC} from 'react';
import Layout from "../components/Layout";
import item_1 from '../assets/images/item_1.png';
import item_2 from '../assets/images/item_2.png';
import item_3 from '../assets/images/item_3.png';
import item_4 from '../assets/images/item_4.png';
import {supportEmail} from "../App";


const about = [
  {title: 'Generative AI Solutions', icon: item_1},
  {title: 'AI-Powered Automation (for internal processes)', icon: item_2},
  {title: 'Predictive Analytics (give industries--lending, real estate, marketing, etc.)', icon: item_3},
  {title: 'AI Consulting', icon: item_4},

]

interface Props {
}

const HomePage: FC<Props> = () => {
  return (
    <Layout footer>
      <h1>Cutting-Edge AI Solutions for Your Business</h1>
      <h4 className='mb-3'>We specialize in building advanced AI products.</h4>
      <div className='d-flex justify-content-start'>
        <a href={`mailto:${supportEmail}`} target='_blank' className='btn btn-primary btn-lg'>Schedule Consultation</a>
      </div>
      <h3 className='mt-5'>About Silda Group LTD:</h3>
      <p className='mt-2'> Revolutionizing industries with our proprietary advanced generative AI products. <br/>Committed to empowering
        businesses with tailor-made solutions</p>

      <div className='items'>
        {about.map((item: any, i: number) => (
          <div className="item" key={i}>
            <img src={item.icon} alt="icon"/>
            <p>{item.title}</p>
          </div>
        ))}
      </div>
    </Layout>
  );
}

export default HomePage;