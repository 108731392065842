import React, {FC } from 'react';

import './header.css'
import {Link} from "react-router-dom";
import {supportEmail} from "../../App";

interface Props {
}

const Header: FC<Props> = () => {

  return (
      <header>
        <Link to={'/'} className='header-logo'><b className='text-tint'>S</b>ilda Group</Link>
        <div className='d-flex align-items-center'>
        <a href={`mailto:${supportEmail}`} target='_blank' className='btn btn-primary'>Schedule Consultation</a>
        </div>
        <div className="header-bg" />
      </header>
  );
}

export default Header;