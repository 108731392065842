import React, {} from 'react';


import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import LegalInfoPage from "./pages/LegalInfoPage";
import Cookies from "./components/Cookies";


export const supportEmail = 'support@sildagroup.ltd'

function App() {

  return (
    <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage/>}/>
          <Route path='/legal-information' element={<LegalInfoPage/>}/>
        </Routes>

    </BrowserRouter>
  );
}

export default App;
