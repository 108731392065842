import React, {FC} from 'react';
import './styles.css';

interface Props {
  size: 'sm' | 'lg' | 'xl';
  className?: string;
  bg?: ('red' | 'yellow')[]
}

const CircleOrion: FC<Props> = ({size, className, bg}) => {

  return (
    <div className={`circle-orion ${size} ${className || ''}`}>
      {bg?.map((item, i) => (
        <div className={`circle-orion-bg ${item}`} key={i}/>
      ))}
    </div>
  );
}

export default CircleOrion;